/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    ul: "ul",
    li: "li",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://2024.pycon.kr/"
  }, "파이콘 한국 2024")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.amazon.com/Amazon-Kindle-Ereader-Family/b?ie=UTF8&node=6669702011"
  }, "킨들 2024")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://openai.com/index/introducing-chatgpt-search/"
  }, "ChatGPT Search")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.apple.com/kr/newsroom/2024/10/apples-new-mac-mini-is-more-mighty-more-mini-and-built-for-apple-intelligence/"
  }, "애플 M4 맥미니")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.apple.com/kr/newsroom/2024/10/new-macbook-pro-features-m4-family-of-chips-and-apple-intelligence/"
  }, "애플 M4 맥북프로")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.apple.com/kr/newsroom/2024/11/apple-vision-pro-is-available-to-pre-order-in-south-korea/"
  }, "애플 비전프로 한국 출시")), "\n");
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
